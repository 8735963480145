import { Tag } from "@pankod/refine-antd";
import { IBookingStatus } from "interfaces";
import { EBookingStatus } from "interfaces/enum";

type BookingStatusProps = {
	status: IBookingStatus;
};

export const BookingStatus: React.FC<BookingStatusProps> = ({ status }) => {
	let color;
	switch (status.code) {
		case EBookingStatus.PaymentPending:
			color = "#f90";
			break;
		case EBookingStatus.Confirmed:
			color = "#87d068";
			break;
		case EBookingStatus.PaymentAccepted:
			color = "#108ee9";
			break;
		case EBookingStatus.Canceled:
			color = "#f00";
			break;
	}

	return <Tag color={color}>{status.name.toUpperCase()}</Tag>;
};
