import {
	Col,
	Divider,
	Form,
	Input,
	Modal,
	notification,
	Row,
	Select,
} from "@pankod/refine-antd";
import { useRequest } from "ahooks";
import { ICity, ICountry, IProperty } from "interfaces";
import { useState } from "react";
import { axiosInstance } from "services/axios-instance";
import { useGetCountryList } from "utils/hooks";

declare type PropertyFormModalPropsType = {
	property: IProperty;
	refetchProperty: () => void;
	children: React.ReactNode;
};

const PropertyFormModal = (props: PropertyFormModalPropsType) => {
	const { property, refetchProperty, children } = props;
	const [showSelectCity, setShowSelectCity] = useState<boolean>(false);

	const { data: countries, loading: getCountriesLoading } = useGetCountryList();
	const getCityService = async (countryId: number) => {
		const res = await axiosInstance.get(`/cities/${countryId || 1}`);

		return res.data.map((city: ICity) => ({
			label: city.name,
			value: city.id,
		}));
	};
	const {
		runAsync: getCityList,
		data: cities,
		loading: getCitiesLoading,
	} = useRequest(getCityService, {
		cacheTime: 30 * 6000,
	});

	const [form] = Form.useForm();
	const [showPropertyForm, setShowPropertyForm] = useState<boolean>(false);
	const onOpen = () => {
		setShowPropertyForm(true);
	};
	const onClose = () => {
		setShowPropertyForm(false);
	};

	const [updatePropertyLoading, setUpdatePropertyLoading] =
		useState<boolean>(false);
	const onUpdate = () => {
		form
			.validateFields()
			.then((property_form) => {
				setUpdatePropertyLoading(true);
				const data = {
					...property,
					...property_form,
				};
				axiosInstance
					.post("/property", data)
					.then((res) => {
						setUpdatePropertyLoading(false);
						if (res.status === 200) {
							// Uploading image
							// await uploadService({file,id,resource})
							notification.success({
								message: "Success",
								description: "Property updated successfully",
							});

							refetchProperty();
							onClose();
						}
					})
					.catch((err) => {
						notification.error({
							message: "Error",
							description: err.message,
						});
						setUpdatePropertyLoading(false);
						console.log(err);
					});
			})
			.catch((info) => {
				console.log("Validate Failed:", info);
			});

		console.log("onUpdate");
	};

	return (
		<div>
			<div onClick={onOpen}>{children}</div>
			<Modal
				visible={showPropertyForm}
				okText="Update"
				cancelText="Cancel"
				okButtonProps={{ ghost: true, loading: updatePropertyLoading }}
				onOk={onUpdate}
				onCancel={onClose}
				keyboard={false}
				closable
				title="Update Property"
				width={1200}
			>
				<Form form={form} layout="vertical" initialValues={property}>
					<Row gutter={20}>
						<Col span={8}>
							<Form.Item
								label="Name"
								name="name"
								rules={[{ required: true, message: "Please input name!" }]}
							>
								<Input placeholder="Enter name" />
							</Form.Item>
						</Col>
						<Col span={8}>
							<Form.Item
								label="Email"
								name="email"
								rules={[{ required: true, message: "Please input email!" }]}
							>
								<Input type="email" placeholder="Enter email" />
							</Form.Item>
						</Col>
						<Col span={8}>
							<Form.Item
								label="Phone"
								name="phone"
								rules={[{ required: true, message: "Please input phone!" }]}
							>
								<Input placeholder="Enter phone" />
							</Form.Item>
						</Col>
					</Row>
					<Divider />
					{/* Address */}
					<Row gutter={20}>
						<Col span={12}>
							<Form.Item
								label="Address One"
								name="addressOne"
								rules={[{ required: true, message: "Please input address!" }]}
							>
								<Input placeholder="Enter Address" />
							</Form.Item>
						</Col>
						<Col span={12}>
							<Form.Item label="Address Two" name="addressTwo">
								<Input placeholder="Enter Address (optional)" />
							</Form.Item>
						</Col>
					</Row>
					<Row gutter={20}>
						<Col span={8}>
							<Form.Item
								label="Country"
								name="countryID"
								rules={[{ required: true, message: "Please input countryID!" }]}
							>
								<Select
									onSelect={(v: number) => {
										setShowSelectCity(false);
										getCityList(v).then((res) => {
											setShowSelectCity(true);
											console.log("onSelect", res);
										});
									}}
									loading={getCountriesLoading}
									placeholder="Select a country"
									options={countries?.map((country: ICountry) => ({
										label: country.name,
										value: country.id,
									}))}
								/>
							</Form.Item>
						</Col>
						<Col span={8}>
							<Form.Item
								label="City"
								name="cityID"
								rules={[{ required: true, message: "Please input cityID!" }]}
							>
								<Select
									disabled={!showSelectCity}
									loading={getCitiesLoading}
									placeholder="Select a city"
									options={cities}
								/>
							</Form.Item>
						</Col>
						<Col span={8}>
							<Form.Item
								label="Postal Code"
								name="postalCode"
								rules={[
									{ required: true, message: "Please input postalCode!" },
								]}
							>
								<Input placeholder="Enter postal code" />
							</Form.Item>
						</Col>
					</Row>
					<Row gutter={20}>
						<Col span={12}>
							<Form.Item label="Latitude" name="locationLat">
								<Input placeholder="Map Latitude" />
							</Form.Item>
						</Col>
						<Col span={12}>
							<Form.Item label="Longitude" name="locationLon">
								<Input placeholder="Map Longitude" />
							</Form.Item>
						</Col>
					</Row>
				</Form>
			</Modal>
		</div>
	);
};

export default PropertyFormModal;
