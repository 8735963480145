import { CSSProperties } from "react";

export const antLayoutSider: CSSProperties = {
  position: "relative",
};

export const antLayoutSiderMobile: CSSProperties = {
  position: "fixed",
  height: "100vh",
  zIndex: 999,
};

export const menuItem: CSSProperties = {
  fontWeight: "bold",
  color: "inherit",
}

export const selectedMenuItem: CSSProperties = {
  color: '#f7ba05',
  fontWeight: "bold",
  boxShadow: "0px 5px 5px rgb(0,0,0, 0.5)",
  backgroundColor: '#004aa3',
}
