import React, { useState } from "react";

import {
	useLogout,
	useTitle,
	CanAccess,
	ITreeMenu,
	useRouterContext,
} from "@pankod/refine-core";
import { AntdLayout, Menu, Grid, Icons, useMenu } from "@pankod/refine-antd";
import {
	antLayoutSider,
	antLayoutSiderMobile,
	menuItem,
	selectedMenuItem,
} from "./styles";
import "./index.less";
import LogoutModal from "../../logout/index.d";

import { LogoutIcon } from "../../logout/index.d";

const { UnorderedListOutlined } = Icons;

export const Sider: React.FC = () => {
	const [collapsed, setCollapsed] = useState<boolean>(false);

	const { mutate: logout } = useLogout();

	const { Link } = useRouterContext();
	const Title = useTitle();
	const { SubMenu } = Menu;

	const { menuItems, selectedKey, defaultOpenKeys } = useMenu();
	const breakpoint = Grid.useBreakpoint();

	const isMobile = !breakpoint.lg;

	const renderTreeView = (tree: ITreeMenu[], selectedKey: string) => {
		return tree.map((item: ITreeMenu) => {
			const { icon, label, route, name, children, parentName } = item;

			if (children.length > 0) {
				return (
					<SubMenu
						key={name}
						icon={icon ?? <UnorderedListOutlined />}
						title={label}
					>
						{renderTreeView(children, selectedKey)}
					</SubMenu>
				);
			}
			const isSelected = route === selectedKey;
			const isRoute = !(parentName !== undefined && children.length === 0);
			return (
				<CanAccess key={route} resource={name.toLowerCase()} action="list">
					<Menu.Item
						key={selectedKey}
						icon={icon ?? (isRoute && <UnorderedListOutlined />)}
						style={isSelected ? selectedMenuItem : menuItem}
					>
						<Link to={route}>
							<span
								style={{
									color: isSelected ? "#f7ba05" : "white",
								}}
							>
								{label}
							</span>
						</Link>
					</Menu.Item>
				</CanAccess>
			);
		});
	};

	// medal
	const [isModalVisible, setIsModalVisible] = useState(false);

	const showModal = () => {
		setIsModalVisible(true);
	};

	const closeModal = () => {
		setIsModalVisible(false);
	};

	return (
		<AntdLayout.Sider
			// this is for when the sider collapse but we dont need it for now
			collapsible
			collapsed={collapsed}
			onCollapse={(collapsed: boolean): void => setCollapsed(collapsed)}
			collapsedWidth={isMobile ? 0 : 80}
			breakpoint="lg"
			style={isMobile ? antLayoutSiderMobile : antLayoutSider}
			// style={antLayoutSider}
			className="bg-primary"
		>
			<div className="w-full text-center my-6">
				{Title && <Title collapsed={collapsed} />}
			</div>
			<Menu
				selectedKeys={[selectedKey]}
				defaultOpenKeys={defaultOpenKeys ?? 1}
				mode="inline"
				onClick={() => {
					if (!breakpoint.lg) {
						setCollapsed(true);
					}
				}}
			>
				{renderTreeView(menuItems, selectedKey)}

				<Menu.Item
					key="logout"
					onClick={showModal}
					icon={
						<LogoutIcon
							style={{
								fontSize: "14px",
							}}
						/>
					}
					style={menuItem}
				>
					Logout
				</Menu.Item>
				<LogoutModal
					isModalVisible={isModalVisible}
					closeModal={closeModal}
					logout={logout}
				/>
			</Menu>
		</AntdLayout.Sider>
	);
};
