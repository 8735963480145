import { CrudFilters, HttpError } from "@pankod/refine-core";

import {
	List,
	Table,
	TextField,
	useTable,
	Row,
	Col,
	Space,
	ShowButton,
	DeleteButton,
} from "@pankod/refine-antd";
import {
	ICity,
	ICountry,
	IProperty,
	PropertyFilterProps,
} from "../../interfaces";
import React from "react";

const PropertyList = () => {
	const { tableProps } = useTable<IProperty, HttpError, PropertyFilterProps>({
		hasPagination: true,
		initialPageSize: 10,

		onSearch: (params) => {
			const filters: CrudFilters = [];
			// const { date } = params;

			// this is for filter checkin checkout but for now we
			// filters.push(
			// 	{
			// 		field: "createdAt",
			// 		operator: "gte",
			// 		value: date ? date[0].startOf("day").toISOString() : undefined,
			// 	},
			// 	{
			// 		field: "createdAt",
			// 		operator: "lte",
			// 		value: date ? date[1].endOf("day").toISOString() : undefined,
			// 	}
			// );

			return filters;
		},
	});

	return (
		<Row gutter={[16, 16]} className="pb14">
			<Col span={24}>
				<List title={<span className="text-primary">Properties</span>}>
					<Table {...tableProps} dataSource={tableProps.dataSource} rowKey="id">
						<Table.Column
							title="ID"
							key="id"
							dataIndex="id"
							render={(value) => <TextField value={value} />}
						/>
						<Table.Column
							title="Country"
							key="country"
							dataIndex="country"
							render={(value: ICountry) => <TextField value={value.name} />}
						/>
						<Table.Column
							title="City"
							key="city"
							dataIndex="city"
							render={(value: ICity) => <TextField value={value.name} />}
						/>

						{/* <Table.Column
							title="Created at"
							key="created_at"
							dataIndex="created_at"
							render={(value) => <DateField value={value} format="LLL" />}
						/> */}
						<Table.Column title="Name" key="name" dataIndex="name" />
						<Table.Column
							title="Phone number"
							key="phone"
							dataIndex="phone"
							render={(v) => <span>{v}</span>}
						/>
						<Table.Column
							title="Address"
							key="addressOne"
							dataIndex="addressOne"
							render={(v) => <span>{v}</span>}
						/>

						{/*action button*/}
						{/* <Table.Column<IProperty>
							fixed="right"
							title="Action"
							dataIndex="actions"
							key="actions"
							align="center"
							render={(_value, record) => <PropertyActions record={record} />}
						/> */}
						<Table.Column<IProperty>
							title="Actions"
							dataIndex="actions"
							render={(_text, record): React.ReactNode => {
								return (
									<Space>
										<ShowButton
											size="small"
											recordItemId={record.id}
											hideText
										/>
										{/* <EditButton size="small" recordItemId={record.id} hideText /> */}
										<DeleteButton
											size="small"
											recordItemId={record.id}
											hideText
										/>
									</Space>
								);
							}}
						/>
					</Table>
				</List>
			</Col>
		</Row>
	);
};

export default PropertyList;
