import { IconComponentProps } from "@ant-design/icons/lib/components/Icon";
import { Button, Icon, Modal } from "@pankod/refine-antd";

import "./index.css";

type propType = {
	isModalVisible: boolean;
	closeModal: Function;
	logout: Function;
};

const LogoSvg = () => (
	<svg
		width="1em"
		height="1em"
		viewBox="0 0 64 64"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M44.2005 8.40978V15.8218L49.1008 11.118V2.37564L46.6507 0H2.54781L0.0976562 2.37564V4.68498L0 4.7514V53.6422L1.76412 55.8278L26.2657 64L29.4019 61.7669V57.0152H46.6424L49.0926 54.6396V52.2639V46.0397L44.1923 41.2885V52.2639H29.4019V12.8761L27.7848 10.6905L9.97807 4.75129H24.5992H44.2005V8.40978ZM24.5016 58.3459L4.90032 51.9792V8.17233L24.5016 14.5391V58.3459ZM56.4522 30.7886H32.0977V26.0373H56.2562L48.4157 18.4352L51.8949 15.1093L63.9987 26.7975V30.1709L51.7969 41.9541L48.3667 38.6282L56.4522 30.7886Z"
			fill="currentColor"
		/>
	</svg>
);

export const LogoutIcon = (props: Pick<IconComponentProps, "style">) => (
	<Icon component={LogoSvg} {...props} />
);

export default function LogoutModal({
	isModalVisible,
	closeModal,
	logout,
}: propType) {
	return (
		<Modal
			visible={isModalVisible}
			footer={null}
			closable={false}
			width={300}
			className="logout-modal"
		>
			<div className="w-full flex flex-col justify-center items-center space-y-4 text-center">
				<LogoutIcon
					style={{
						fontSize: "64px",
						color: "#424242",
					}}
				/>
				<h1 className="text-lg font-medium">
					You're leaving...
					<br />
					Are you sure?
				</h1>
				<Button
					onClick={() => closeModal()}
					className="w-full text-white bg-primary h-fit py-2 text-lg border-2"
				>
					No!
				</Button>
				<Button
					onClick={() => logout()}
					className="w-full text-primary border-primary border-2 h-fit py-2 text-lg
                hover:bg-primary hover:text-white"
				>
					Yes, Log me out
				</Button>
			</div>
		</Modal>
	);
}
