import { PlusOutlined } from "@ant-design/icons";
import { message, Modal, Upload } from "antd";
import type { RcFile, UploadProps } from "antd/es/upload";
import type { UploadFile } from "antd/es/upload/interface";
import { IPhoto } from "interfaces";
import React, { useState } from "react";
import { axiosInstance } from "services/axios-instance";
import { getBase64 } from "utils/functions";

declare type PhotoUploaderPropsType = {
	resource_id: number;
	photos: IPhoto[];
	resource: "property" | "room";
} & Partial<UploadProps>;
const mapPhotosData = (photos: IPhoto[]): UploadFile[] => {
	return photos.map((photo) => {
		return {
			uid: photo.id.toString(),
			name: photo.name,
			status: "done",
			url: photo.url,
		};
	});
};
const PhotoUploader: React.FC<PhotoUploaderPropsType> = (props) => {
	const { photos, resource, resource_id } = props;
	const [previewOpen, setPreviewOpen] = useState<boolean>(false);
	const [previewImage, setPreviewImage] = useState("");
	const [previewTitle, setPreviewTitle] = useState("");
	const [fileList, setFileList] = useState<UploadFile[]>(mapPhotosData(photos));

	const handleCancel = () => setPreviewOpen(false);

	const handlePreview = async (file: UploadFile) => {
		if (!file.url && !file.preview) {
			file.preview = await getBase64(file.originFileObj as RcFile);
		}

		setPreviewImage(file.url || (file.preview as string));
		setPreviewOpen(true);
		setPreviewTitle(
			file.name || file.url!.substring(file.url!.lastIndexOf("/") + 1)
		);
	};

	const handleChange: UploadProps["onChange"] = ({ fileList: newFileList }) =>
		setFileList(newFileList);

	const uploadButton = (
		<div>
			<PlusOutlined />
			<div style={{ marginTop: 8 }}>Upload</div>
		</div>
	);
	const handleUploader: UploadProps["customRequest"] = (options) => {
		const { file, onSuccess, onError } = options;
		const formData = new FormData();
		formData.append("photo", file);
		formData.append("resource_id", resource_id.toString());
		formData.append("resource_type", resource);
		axiosInstance.post("/photo/upload", formData).then((res) => {
			if (res.status === 200) {
				//@ts-ignore
				onSuccess(res.data, file);
				message.success(`Photo uploaded successfully`);
			} else {
				//@ts-ignore
				onError(res.data);
				message.error(`Photo upload failed`);
			}
		});
	};
	const handleRemove: UploadProps["onRemove"] = async (file) => {
		const { uid } = file;
		await axiosInstance.get(`/photo/delete/${uid}`).then((res) => {
			if (res.status === 200) {
				message.success(`Photo deleted successfully`);
			} else {
				message.error(`Photo delete failed`);
			}
		});
	};
	return (
		<>
			<Upload
				listType="picture-card"
				fileList={fileList}
				onPreview={handlePreview}
				onChange={handleChange}
				customRequest={handleUploader}
				onRemove={handleRemove}
				{...props}
			>
				{fileList.length >= 8 ? null : uploadButton}
			</Upload>
			<Modal
				visible={previewOpen}
				title={previewTitle}
				footer={null}
				onCancel={handleCancel}
			>
				<img alt="example" style={{ width: "100%" }} src={previewImage} />
			</Modal>
		</>
	);
};

export default PhotoUploader;
