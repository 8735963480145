import React from "react";
import {
	Button,
	Card,
	Col,
	Form,
	Icons,
	Input,
	Row,
	Show,
} from "@pankod/refine-antd";
import { useCustom } from "@pankod/refine-core";
import { useGetCityByCityId, useGetCountryById } from "utils/hooks";
import UserProfileFormModal from "./UserProfleFormModal";
import UserPasswordFormModal from "./UserPasswordFormModal";

const UserAccountInfo: React.FC = () => {
	const { data, isLoading } = useCustom({
		method: "get",
		url: "supplier/profile",
		dataProviderName: "default",
	});
	const { data: city } = useGetCityByCityId(1, 1);
	const { data: country } = useGetCountryById(1);

	return (
		<Card
			loading={isLoading}
			bodyStyle={{
				padding: 20,
			}}
			extra={
				<Row gutter={8}>
					<Col>
						<UserPasswordFormModal
							user={data?.data.data}
							refetchProfile={() => {
								window.location.reload();
							}}
							children={
								<Button type="primary" ghost icon={<Icons.LockFilled />}>
									Change Password
								</Button>
							}
						/>
					</Col>
					<Col>
						<UserProfileFormModal
							user={data?.data.data}
							refetchProfile={() => {
								window.location.reload();
							}}
							children={
								<Button type="primary" ghost icon={<Icons.EditOutlined />}>
									Edit Profile
								</Button>
							}
						/>
					</Col>
				</Row>
			}
			className="h-full flex-grow-1"
			title="User Account Setting"
		>
			<Show headerButtons title="">
				<Form initialValues={data?.data?.data}>
					<Row gutter={20}>
						<Col span={12}>
							<Form.Item label="First name" name="firstName">
								<Input size="large" readOnly disabled />
							</Form.Item>
						</Col>
						<Col span={12}>
							<Form.Item label="Last name" name="lastName">
								<Input size="large" readOnly disabled />
							</Form.Item>
						</Col>
					</Row>
					<Row gutter={20}>
						<Col span={12}>
							<Form.Item label="Username" name="username">
								<Input size="large" readOnly disabled />
							</Form.Item>
						</Col>
						<Col span={12}>
							<Form.Item label="Password" name="password">
								<Input.Password size="large" readOnly disabled />
							</Form.Item>
						</Col>
					</Row>

					<Row gutter={20}>
						<Col span={12}>
							<Form.Item label="Email" name="email">
								<Input size="large" readOnly disabled />
							</Form.Item>
						</Col>
						<Col span={12}>
							<Form.Item label="Phone" name="phone">
								<Input size="large" readOnly disabled />
							</Form.Item>
						</Col>
					</Row>
					<Row gutter={20}>
						<Col span={12}>
							<Form.Item label="Country">
								<Input
									size="large"
									readOnly
									disabled
									defaultValue={country?.name}
								/>
							</Form.Item>
						</Col>
						<Col span={12}>
							<Form.Item label="City">
								<Input
									size="large"
									readOnly
									disabled
									defaultValue={city?.name}
								/>
							</Form.Item>
						</Col>
					</Row>
				</Form>
			</Show>
		</Card>
	);
};

export default UserAccountInfo;
