import {
	Button,
	Card,
	Col,
	Descriptions,
	Divider,
	Icons,
	Image,
	notification,
	Popconfirm,
	Row,
	Space,
} from "@pankod/refine-antd";
import { IProperty, IRoom, IRoomType } from "interfaces";
import { useState } from "react";
import { axiosInstance } from "services/axios-instance";
import PhotoUploader from "./PhotoUploader";
import RoomFormModal from "./RoomFormModal";
import RoomPriceConfig from "./RoomPriceConfig";
import { useGetAmenities } from "utils/hooks";

declare type PropertyRoomListPropsType = {
	property: IProperty;
	refetchProperty: () => void;
	roomTypeList: IRoomType[] | undefined;
	getRoomTypeLoading: boolean;
};

const PropertyRoomList = (props: PropertyRoomListPropsType) => {
	const { property, refetchProperty, getRoomTypeLoading, roomTypeList } = props;

	const getRoomTypeName = (id: number) => {
		const roomType = roomTypeList?.find((item) => item.id === id);
		return roomType?.name;
	};

	const [deleteRoomLoading, setDeleteRoomLoading] = useState(false);
	const onDeleteRoom = (
		e: React.MouseEvent<HTMLElement> | undefined,
		room: IRoom
	) => {
		setDeleteRoomLoading(true);
		const rooms_form = property.rooms.filter((item) => item.id !== room.id);
		const data: IProperty = {
			...property,
			rooms: rooms_form,
		};
		axiosInstance
			.post("/property", data)
			.then((res) => {
				if (res.status === 200) {
					refetchProperty();
				}
			})
			.catch((err) => {
				console.log(err);
				notification.error({
					message: "Error",
					description: "Error deleting room",
				});
			})
			.finally(() => {
				setDeleteRoomLoading(false);
			});
	};
	const { data: amenityList, loading: getAmenityLoading } = useGetAmenities();

	return (
		<div>
			<Row gutter={20}>
				{property.rooms &&
					property.rooms.map((room, idx) => (
						<Col key={room.id} span={12} className="pb-10">
							<Card
								bodyStyle={{ borderColor: "#607EAA" }}
								style={{ borderRadius: "10px", borderColor: "#607EAA" }}
								title={
									<p className="font-bold text-lg text-blue-800">
										{getRoomTypeName(room.roomTypeID)}
									</p>
								}
								extra={
									<Space>
										<RoomFormModal
											property={property}
											currentRoom={room}
											getRoomTypeLoading={
												getRoomTypeLoading || getAmenityLoading
											}
											roomTypeList={roomTypeList}
											amenityList={amenityList}
											refetchProperty={refetchProperty}
											children={
												<Button
													type="primary"
													ghost
													icon={<Icons.EditOutlined />}
												>
													Edit
												</Button>
											}
										/>

										<Popconfirm
											title="Are you sure to delete this room?"
											onConfirm={(e) => onDeleteRoom(e, room)}
											okText="Yes"
											cancelText="No"
											okButtonProps={{
												ghost: true,
												loading: deleteRoomLoading,
											}}
										>
											<Button
												type="primary"
												danger
												ghost
												icon={<Icons.DeleteOutlined />}
											>
												Delete
											</Button>
										</Popconfirm>
									</Space>
								}
							>
								<div className="flex flex-wrap justify-center space-x-2 mb-4">
									<PhotoUploader
										resource_id={room.id}
										photos={room.photos}
										resource="room"
									/>
								</div>

								<Descriptions column={1}>
									<Descriptions.Item label="Description">
										{room.description}
									</Descriptions.Item>
									<Descriptions.Item label="Number of people">
										{room.numPersons}
									</Descriptions.Item>
									<Descriptions.Item label="Number of room">
										{room.numRooms}
									</Descriptions.Item>
								</Descriptions>
								{room.pricing && room.pricing.length > 0 && (
									<div>
										<Divider dashed className="border-blue-600 opacity-50" />
										<p className="text-lg pb-2  font-bold">
											Price Configuration:
										</p>
										{room.pricing.map((item) => (
											<RoomPriceConfig key={item.id} item={item} />
										))}
									</div>
								)}
							</Card>
						</Col>
					))}
			</Row>
		</div>
	);
};

export default PropertyRoomList;
