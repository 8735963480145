import { Descriptions } from "@pankod/refine-antd";
import { IProperty } from "interfaces";

declare type PropertyInfoPropsType = {
	property: IProperty;
};

const PropertyInfo = (props: PropertyInfoPropsType) => {
	const { property } = props;

	return (
		<Descriptions>
			<Descriptions.Item label="Name">
				<p className="font-bold">{property.name}</p>
			</Descriptions.Item>
			<Descriptions.Item label="Email">{property.email}</Descriptions.Item>
			<Descriptions.Item label="Telephone">{property.phone}</Descriptions.Item>
			<Descriptions.Item label="Country">
				{property.country.name}
			</Descriptions.Item>
			<Descriptions.Item label="City">{property.city.name}</Descriptions.Item>
			<Descriptions.Item label="Address">
				{property.addressOne}, {property.addressTwo}
			</Descriptions.Item>
			<Descriptions.Item label="Postal Code">
				{property.postalCode}
			</Descriptions.Item>
		</Descriptions>
	);
};
export default PropertyInfo;
