import { useLogin } from "@pankod/refine-core";

import { useState } from "react";
import LoginComponent from "./Login";
import RegisterComponent from "./Register";
import "./index.css";
// import { ReservaLogo } from "../reserva-logo.d";
import LogoBrand from "components/logo";
import ForgetPasswordComponent from "./ForgetPassword";
export default function CustomLoginPage() {
	// page state
	const [pageTitle, setPageTitle] = useState("Login");
	const { mutate: login, isLoading } = useLogin();
	const onFinish = async (values: any) => {
		login({
			...values,
			isRegistering: pageTitle === "Register",
		});
	};

	return (
		<div className="w-full min-h-screen bg-primary flex flex-col justify-center items-center">
			<div className="font-black text-3xl text-white mb-12">
				<LogoBrand />
			</div>
			<div
				className="px-4 pt-6 bg-white rounded-md w-2/3 sm:w-3/5 md:w-2/5 lg:w-1/3
                flex flex-col items-center space-y-4 login-page"
			>
				<h1 className="text-lg font-bold">{pageTitle}</h1>
				<div className="w-full">
					{pageTitle === "Login" && (
						<LoginComponent
							onFinish={onFinish}
							isLoading={isLoading}
							setPageTitle={setPageTitle}
						/>
					)}
					{pageTitle === "Register" && (
						<RegisterComponent
							onFinish={onFinish}
							isLoading={isLoading}
							setPageTitle={setPageTitle}
						/>
					)}
					{pageTitle === "Reset Password" && (
						<ForgetPasswordComponent setPageTitle={setPageTitle} />
					)}
				</div>
			</div>
		</div>
	);
}
