import {BaseRecord} from "@pankod/refine-core";
import {ReactNode} from "react";
import {Typography, Card, Row, Col, Avatar, Icons} from '@pankod/refine-antd';

const {Text} = Typography;

const courierInfoBox = (text: string, icon: ReactNode, value?: string) => (
    <div className="bg-green-500 py-2 px-4 space-x-4 flex flex-row items-center rounded-md">
        {icon}
        <div className="text">
            <Text style={{color: "#ffffff"}}>{text.toUpperCase()}</Text>
            <br />
            <Text style={{color: "#ffffff"}}>{value}</Text>
        </div>
    </div>
);

export const CustomerDetail = ({record}: { record: BaseRecord | undefined }) => {
    return <Card>
        <Row justify="center" align="middle">
            <Col span={8}>
                <div className="w-full flex flex-row justify-start items-center space-x-8">
                    <Avatar
                        size={108}
                        src={record?.courier.avatar[0].url}
                    />
                    <span className="font-bold text-lg text-black">Susan Lee</span>
                </div>
            </Col>

            <Col span={16}>
                <div className="flex flex-row space-x-4 justify-end items-center">
                    {courierInfoBox(
                        "Phone",
                        <Icons.MobileOutlined
                            className="mobile"
                            style={{color: "#ffff", fontSize: 32}}
                        />,
                        record?.courier.gsm,
                    )}
                    {courierInfoBox(
                        "Email",
                        <Icons.MailOutlined
                            style={{color: "#ffff", fontSize: 32}}/>,
                        "SusanLee@gmail.com",
                    )}
                </div>
            </Col>
        </Row>
    </Card>;
}




