import React from "react";
import { Card, Icons, NumberField } from "@pankod/refine-antd";
import { Pie, PieConfig } from "@ant-design/charts";

const { CaretUpOutlined, CaretDownOutlined } = Icons;

type TotalBookingProps = {
	totalBooking: number;
	upcomingBooking: number;
	pendingCancellations: number;
};
const TotalBooking: React.FC<TotalBookingProps> = ({
	totalBooking,
	pendingCancellations,
	upcomingBooking,
}) => {
	// pie chart
	const data = [
		{
			type: "Upcoming",
			value: upcomingBooking,
		},
		{
			type: "Reject",
			value: 0,
		},
		{
			type: "Pending Cancellation",
			value: pendingCancellations,
		},
	];

	const pieConfig: PieConfig = {
		data,
		height: 150,
		width: 150,
		padding: 0,
		angleField: "value",
		colorField: "type",
		color: ({ type }) => {
			if (type === "Upcoming") {
				return "#3b82f6";
			}
			if (type === "Reject") {
				return "#ef4444";
			}
			if (type === "Pending Cancellation") {
				return "#eab308";
			}
			return "#000";
		},
	};

	// Booking type component
	// PROP: label:= name of the booking type
	// PROP: color:= classname of tailwind background color
	const BookingTypeLabel = ({
		label,
		color,
	}: {
		label: string;
		color: string;
	}) => {
		return (
			<div className="no-wrap w-max flex flex-row items-center space-x-2 text-xs">
				<div className={`${color} h-2 w-2 rounded-sm`} />
				<div className="text-black text-xs">{label}</div>
			</div>
		);
	};

	return (
		<Card
			bodyStyle={{
				padding: 20,
			}}
			className="h-full flex-grow-1"
		>
			<div className="flex flex-row justify-between items-start">
				<div className="flex flex-col flex-wrap justify-around items-start">
					<div className="font-bold text-grey-700 no-wrap">Total Booking</div>
					<div className="flex flex-row no-wrap items-center space-x-2">
						{/* this is just a dummy data for now*/}
						{true && true ? (
							<CaretUpOutlined className="text-green-500 text-xl" />
						) : (
							<CaretDownOutlined className="text-red-500 text-xl" />
						)}
						<NumberField
							style={{ fontSize: 24, color: "black" }}
							strong
							//this is just a dummy data for now
							value={totalBooking ?? 0}
						/>
					</div>
				</div>
				<Pie legend={false} label={false} {...pieConfig} />
				<div className="w-fit">
					<BookingTypeLabel label="Accept" color="bg-blue-500" />
					<BookingTypeLabel label="Reject" color="bg-red-500" />
					<BookingTypeLabel label="Pending" color="bg-yellow-500" />
				</div>
			</div>
		</Card>
	);
};

export default TotalBooking;
