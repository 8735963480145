import { useRequest } from "ahooks";
import { IRoomType } from "interfaces";
import { axiosInstance } from "services/axios-instance";
import {
	IAmenity,
	IBookingStatus,
	ICity,
	ICountry,
} from "./../interfaces/index.d";

export const useGetRoomTypeList = () => {
	const onGetRoomTypeList = async () => {
		const res = await axiosInstance.get("/room/types");
		const data = await res.data;
		return data;
	};
	const { data, loading } = useRequest(onGetRoomTypeList, {
		cacheTime: 30 * 6000,
		cacheKey: "room-types",
		onSuccess: (data: IRoomType[]) => {
			console.log("roomType", data);
		},
	});

	return { data, loading };
};

export const useGetCountryList = () => {
	const onGetCountryList = async () => {
		const res = await axiosInstance.get("/countries");
		const data = await res.data;
		return data;
	};
	const { data, loading } = useRequest(onGetCountryList, {
		cacheTime: 30 * 6000,
		cacheKey: "country",
		onSuccess: (data: ICountry[]) => {
			console.log(data);
		},
	});

	return { data, loading };
};

export const useGetCityList = (countryId: number) => {
	const onGetCityList = async () => {
		const res = await axiosInstance.get(`/cities/${countryId}`);
		const data = await res.data;
		return data;
	};
	const { data, loading } = useRequest(onGetCityList, {
		cacheTime: 30 * 6000,
		cacheKey: `city-${countryId}`,
		onSuccess: (data: ICity[]) => {
			console.log(data);
		},
	});

	return { data, loading };
};

export const useGetCityByCityId = (cityId: number, countryId: number) => {
	const { data: cities, loading } = useGetCityList(countryId);
	const data = cities?.find((city) => city.id === cityId);
	return { data, loading };
};

export const useGetCountryById = (countryId: number) => {
	const { data: countries, loading } = useGetCountryList();
	const data = countries?.find((country) => country.id === countryId);
	return { data, loading };
};

export const useGetAmenities = () => {
	const onGetAmenities = async () => {
		const res = await axiosInstance.get("/aminities");
		const data = await res.data;
		return data as IAmenity[];
	};
	return useRequest(onGetAmenities, {
		cacheTime: 300 * 6000,
		cacheKey: "amenities-cache",
	});
};

export const useGetStatusBookings = () => {
	const onGetStatusBookings = async () => {
		const res = await axiosInstance.get("/status?t=booking");
		const data = await res.data;
		return data as IBookingStatus[];
	};
	return useRequest(onGetStatusBookings, {
		cacheTime: 300 * 6000,
		cacheKey: "status-bookings-cache",
	});
};

export const useBookingActions = () => {
	const rejectBooking = async (bookingId: number) => {
		const res = await axiosInstance.post("/supplier/booking/cancel", {
			bookingId,
			action: "confirm",
		});
		if (res.status === 200) {
			return true;
		}
		return false;
	};
	const confirmBooking = async (bookingId: number) => {
		const res = await axiosInstance.post("/supplier/booking/confirm", {
			bookingId,
			action: "confirm",
		});
		if (res.status === 200) {
			return true;
		}
		return false;
	};
	return { rejectBooking, confirmBooking };
};
