import { useShow } from "@pankod/refine-core";
import {
	Button,
	Card,
	Divider,
	Icons,
	Show,
	Skeleton,
	Space,
} from "@pankod/refine-antd";
import React from "react";
import { PropertyFormModal, PropertyRoomList } from "components/property";
import { IProperty, IRoom } from "interfaces";
import { useGetAmenities, useGetRoomTypeList } from "utils/hooks";
import RoomFormModal from "components/property/RoomFormModal";
import PropertyInfo from "components/property/PropertyInfo";
import PhotoUploader from "components/property/PhotoUploader";

const PropertyShow: React.FC = () => {
	const { queryResult } = useShow();
	const { data, isLoading, refetch, isRefetching } = queryResult;
	const { data: roomTypeList, loading: getRoomTypeLoading } =
		useGetRoomTypeList();
	const property = data?.data as IProperty;
	const record = {
		...property,
		rooms: property?.rooms?.map((room) => ({
			...room,
			aminities: room?.aminities?.map((amenity: any) => amenity?.aminity?.id),
		})),
	} as IProperty;

	const { data: amenityList, loading: getAmenityLoading } = useGetAmenities();

	return (
		<Show>
			<Space size={20} direction="vertical" style={{ width: "100%" }}>
				{/* <Button ghost type="primary" onClick={() => refetch()}>
				Update
				{isRefetching && "Refetching..."}
				{isLoading && "isLoading..."}
			</Button> */}
				{isLoading || isRefetching ? (
					<Skeleton active />
				) : (
					<Card
						headStyle={{
							borderBottom: 0,
						}}
						title={
							<span className="text-primary font-bold text-lg">
								Property Information
							</span>
						}
						extra={
							<PropertyFormModal
								property={record}
								refetchProperty={refetch}
								children={
									<Button type="primary" ghost icon={<Icons.EditOutlined />}>
										Edit Property Info
									</Button>
								}
							/>
						}
					>
						<div className="flex flex-wrap justify-center pt-4 pb-8 ">
							<PhotoUploader
								resource_id={record.id}
								photos={record.photos}
								resource="property"
							/>
						</div>

						<PropertyInfo property={record} />
						<Divider />
						<div className="flex justify-end">
							<RoomFormModal
								property={record}
								refetchProperty={refetch}
								currentRoom={{} as IRoom}
								getRoomTypeLoading={getRoomTypeLoading || getAmenityLoading}
								roomTypeList={roomTypeList}
								amenityList={amenityList}
								children={
									<Button
										ghost
										type="primary"
										icon={<Icons.PlusSquareOutlined />}
									>
										Add Room
									</Button>
								}
							/>
						</div>
						<Space style={{ width: "100%" }}>
							<PropertyRoomList
								property={record}
								refetchProperty={refetch}
								getRoomTypeLoading={getRoomTypeLoading}
								roomTypeList={roomTypeList}
							/>
						</Space>
					</Card>
				)}
			</Space>
		</Show>
	);
};

export default PropertyShow;
