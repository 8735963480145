import { Button, Form, Input } from "@pankod/refine-antd";

type propsType = {
	onFinish: Function;
	setPageTitle: Function;
	isLoading: boolean;
};

export default function RegisterComponent({
	onFinish,
	setPageTitle,
	isLoading,
}: propsType) {
	return (
		<Form
			id="register"
			onSubmitCapture={(e) => e.preventDefault()}
			onFinish={(e) => onFinish(e)}
			layout="vertical"
		>
			<div className="div grid grid-cols-2 gap-4">
				<Form.Item
					name="firstName"
					rules={[
						{
							required: true,
							message: "Firstname cannot be empty",
						},
					]}
				>
					<Input
						className="p-2 focus:bg-none"
						size="middle"
						placeholder="First name"
					/>
				</Form.Item>
				<Form.Item
					name="lastName"
					rules={[
						{
							required: true,
							message: "Lastname cannot be empty",
						},
					]}
				>
					<Input
						className="p-2 focus:bg-none"
						size="middle"
						placeholder="Last name"
					/>
				</Form.Item>
			</div>
			<Form.Item
				name="email"
				rules={[
					{
						required: true,
						message: "Email cannot be empty",
					},
				]}
			>
				<Input
					className="p-2 focus:bg-none"
					size="middle"
					placeholder="Email"
				/>
			</Form.Item>
			<Form.Item
				name="password"
				rules={[
					{
						required: true,
						message: "Password cannot be empty",
					},
					{
						min: 6,
						message: "The password must be at least 6 characters.",
					},
				]}
			>
				<Input.Password
					className="p-2 focus:bg-none"
					size="middle"
					placeholder="Password"
				/>
			</Form.Item>
			<Form.Item
				name="password_confirmation"
				rules={[
					{
						required: true,
						message: "Password cannot be empty",
					},
					{
						min: 6,
						message: "The password must be at least 6 characters.",
					},
				]}
			>
				<Input.Password
					className="p-2 focus:bg-none"
					size="middle"
					placeholder="Password confirmation"
				/>
			</Form.Item>

			{/*buttons section*/}
			<Form.Item className="login-button">
				<Button
					type="primary"
					htmlType="submit"
					className="py-1 h-fit font-medium text-white
                                w-full border-2 bg-primary box-border
                                hover:bg-white hover:text-primary hover:border-primary
                                "
					loading={isLoading}
				>
					Register
				</Button>
			</Form.Item>
			<Form.Item>
				<div className="w-full flex justify-center items-center">
					<span>Already has an account?</span>
					<div
						tabIndex={0}
						onClick={() => setPageTitle("Login")}
						className="p-2 cursor-pointer hover:font-medium text-primary"
					>
						Login
					</div>
				</div>
			</Form.Item>
		</Form>
	);
}
