import { useCustom } from "@pankod/refine-core";
import { Card, DatePicker, Icons, NumberField } from "@pankod/refine-antd";
import type { RangePickerProps } from "antd/es/date-picker";
import "./totalRevenue.less";
import dayjs, { Dayjs } from "dayjs";
import { useMemo, useState } from "react";
import { ISalesChart } from "../../interfaces";
import { Line, LineConfig } from "@ant-design/charts";

const { RangePicker } = DatePicker;
const { CaretUpOutlined, CaretDownOutlined } = Icons;

const TotalRevenue = ({ totalRevenue }: { totalRevenue: number }) => {
	// RangePicker
	// @ts-ignore
	const thisWeek: RangePickerProps<dayjs> = [
		dayjs().startOf("week"),
		dayjs().endOf("week"),
	];
	// @ts-ignore
	const lastWeek: RangePickerProps<dayjs> = [dayjs().day(-7), dayjs().day(-1)];
	// @ts-ignore
	const thisMonth: RangePickerProps<dayjs> = [
		dayjs().startOf("month"),
		dayjs().endOf("month"),
	];
	// @ts-ignore
	const lastMonth: RangePickerProps<dayjs> = [
		dayjs().date(1).month(dayjs().subtract(1, "month").month()),
		dayjs().date(0).month(dayjs().subtract(1, "month").month()),
	];
	// @ts-ignore
	const lastYear: RangePickerProps<dayjs> = [
		dayjs().date(1).month(0).year(dayjs().subtract(1, "year").year()),
		dayjs().date(0).month(11).year(dayjs().subtract(1, "year").year()),
	];
	// @ts-ignore
	const thisYear: RangePickerProps<dayjs> = [
		dayjs().startOf("year"),
		dayjs().endOf("year"),
	];

	const [dateRange, setDateRange] = useState<[Dayjs, Dayjs]>(thisWeek);
	const [start, end] = dateRange;

	// line chart
	const url = `https://api.finefoods.refine.dev/dailyRevenue`;

	const query = {
		start,
		end,
	};

	const { data, isLoading } = useCustom<{
		data: ISalesChart[];
		total: number;
		trend: number;
	}>({
		url,
		method: "get",
		config: {
			query,
		},
	});

	const config = useMemo(() => {
		const config: LineConfig = {
			data: data?.data.data || [],
			loading: isLoading,
			xField: "date",
			yField: "value",
			color: "#096dd9",
			tooltip: {
				// @ts-ignore
				showCrosshairs: false,
				marker: {
					fill: "#096dd9",
				},
				customContent: (title, data) => {
					return `<div style="padding: 8px 4px; font-size:16px; color:#fff !important; font-weight:600">${data[0]?.value}$</div>`;
				},
				domStyles: {
					"g2-tooltip": {
						background: "rgba(0, 0, 0, 0.3)",
						boxShadow: "unset",
					},
				},
			},
			xAxis: {
				label: null,
				line: null,
			},
			yAxis: {
				label: null,
				grid: null,
			},
			// smooth: true,
			lineStyle: {
				lineWidth: 2,
			},
			padding: [10, 0],
			// appendPadding: 10,
			height: 80,
			style: {
				maxHeight: "80px",
				maxWidth: "100%",
			},
		};

		return config;
	}, [data, isLoading]);

	return (
		<Card
			bodyStyle={{
				padding: 20,
				paddingBottom: 0,
			}}
			className="h-full flex-grow-1"
		>
			<div className="flex flex-row flex-wrap justify-between items-center">
				<div className="font-bold text-grey-700">Total Revenue</div>
				<div className="flex flex-row no-wrap items-center space-x-2">
					{(data?.data?.trend ?? 0) > 0 ? (
						<CaretUpOutlined className="text-green-500 text-xl" />
					) : (
						<CaretDownOutlined className="text-red-500 text-xl" />
					)}
					<NumberField
						style={{ fontSize: 24, color: "black" }}
						strong
						options={{
							currency: "USD",
							style: "currency",
							notation: "standard",
						}}
						value={totalRevenue ?? 0}
					/>
				</div>
			</div>
			{/* <RangePicker
				size="middle"
				ranges={{
					"This week": thisWeek,
					"Last week": lastWeek,
					"This month": thisMonth,
					"Last month": lastMonth,
					"Last year": lastYear,
					"This year": thisYear,
				}}
				onChange={(values) => {
					if (values && values[0] && values[1]) {
						console.log(thisMonth === values);
						setDateRange([values[0], values[1]]);
					} else {
						setDateRange(thisWeek);
					}
				}}
				defaultValue={thisWeek}
			/> */}
			<div className="my-4">
				<Line {...config} />
			</div>
		</Card>
	);
};

export default TotalRevenue;
