import { Button, Form, Input } from "@pankod/refine-antd";

type propsType = {
	onFinish: Function;
	isLoading: boolean;
	setPageTitle: Function;
};

export default function LoginComponent({
	onFinish,
	isLoading,
	setPageTitle,
}: propsType) {
	return (
		<Form
			id="login"
			onSubmitCapture={(e) => e.preventDefault()}
			onFinish={(e) => onFinish(e)}
			layout="vertical"
		>
			<Form.Item
				name="email"
				rules={[
					{
						required: true,
						message: "Email cannot be empty",
					},
				]}
			>
				<Input
					className="p-2 focus:bg-none"
					size="middle"
					placeholder="Email"
				/>
			</Form.Item>
			<Form.Item
				name="password"
				rules={[
					{
						required: true,
						message: "Password cannot be empty",
					},
					{
						min: 6,
						message: "The password must be at least 6 characters.",
					},
				]}
			>
				<Input.Password
					className="p-2 focus:bg-none"
					size="middle"
					placeholder="Password"
				/>
			</Form.Item>
			<div className="w-full flex justify-end items-center pb-4 cursor-pointer hover:font-medium text-primary">
				<span onClick={() => setPageTitle("Reset Password")}>
					Forgetting your password?
				</span>
			</div>

			<Form.Item className="login-button">
				<Button
					type="primary"
					htmlType="submit"
					className="py-1 h-fit font-medium text-white
                                w-full border-2 bg-primary box-border
                                hover:bg-white hover:text-primary hover:border-primary
                                "
					loading={isLoading}
				>
					Log in
				</Button>
			</Form.Item>
			<Form.Item>
				<div className="w-full flex justify-center items-center">
					<div
						tabIndex={0}
						onClick={() => setPageTitle("Register")}
						className="p-2 cursor-pointer text-primary hover:text-medium"
					>
						Register
					</div>
				</div>
			</Form.Item>
		</Form>
	);
}
