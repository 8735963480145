export function SaveTokenToCookie(token: String) {
    document.cookie = `token=${token};SameSite=Lax`;
}

export function DeleteTokenFromCookie() {
    document.cookie = `token=; expires=Thu, 01 Jan 1970 00:00:00 UTC;SameSite=Lax`;
}

export function GetTokenFromCookie() {
    let name = "token=";
    let decodedCookie = decodeURIComponent(document.cookie);
    let ca = decodedCookie.split(';');
    for (let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) === ' ') {
            c = c.substring(1);
        }
        if (c.indexOf(name) === 0) {
            return c.substring(name.length, c.length);
        }
    }
    return undefined;
}
