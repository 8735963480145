import { Refine } from "@pankod/refine-core";
import {
	notificationProvider,
	ReadyPage,
	ErrorComponent,
} from "@pankod/refine-antd";
import "styles/antd.less";
import routerProvider from "@pankod/refine-react-router-v6";
import dataProvider from "@pankod/refine-simple-rest";
import { authProvider } from "./services/auth-provider";
import {
	Title,
	Header,
	Sider,
	Footer,
	Layout,
	OffLayoutArea,
} from "components/layout";

import "index.css";

import {
	axiosInstance,
	initializeAxiosInstance,
} from "./services/axios-instance";

import { Icons } from "@pankod/refine-antd";
import CustomLoginPage from "./components/login";
import { Dashboard } from "./pages/dashboard";
import { BookingList, BookingShow } from "./pages/booking";
import { PropertyCreate, PropertyList, PropertyShow } from "./pages/property";
import { RevDataProvider } from "services/data-provider";
import { UserAccountForm, UserAccountInfo } from "pages/account";
import { BASE_API_URL } from "constants/index";
console.log(BASE_API_URL);
function App() {
	initializeAxiosInstance();
	return (
		<Refine
			warnWhenUnsavedChanges
			notificationProvider={notificationProvider}
			ReadyPage={ReadyPage}
			catchAll={<ErrorComponent />}
			routerProvider={routerProvider}
			DashboardPage={Dashboard}
			dataProvider={{
				default: RevDataProvider(BASE_API_URL, axiosInstance),

				test: dataProvider("https://api.finefoods.refine.dev", axiosInstance),
			}}
			authProvider={authProvider}
			LoginPage={CustomLoginPage}
			resources={[
				{
					name: "supplier/booking",
					icon: <Icons.CalendarOutlined />,
					list: BookingList,
					show: BookingShow,
					options: {
						label: "Bookings",
					},
				},
				{
					name: "property",
					icon: <Icons.BarsOutlined />,
					list: PropertyList,
					show: PropertyShow,
					create: PropertyCreate,
					options: {
						label: "Properties",
					},
				},
				{
					name: "supplier/profile",
					icon: <Icons.UserOutlined />,
					list: UserAccountInfo,
					options: {
						label: "Account",
					},
				},
			]}
			Title={Title}
			Header={Header}
			Sider={Sider}
			Footer={Footer}
			Layout={Layout}
			OffLayoutArea={OffLayoutArea}
		/>
	);
}

export default App;
