import { useUpdate } from "@pankod/refine-core";
import { Button, Dropdown, FormProps, Icons, Menu } from "@pankod/refine-antd";
import { useBookingActions } from "utils/hooks";
import { BookingFilterProps, IBooking } from "interfaces";
import { EBookingStatus } from "interfaces/enum";
import { useState } from "react";

type BookingActionProps = {
	record: IBooking;
	refetch: () => void;
};

export const BookingActions: React.FC<BookingActionProps> = ({
	record,
	refetch,
}) => {
	const { confirmBooking, rejectBooking } = useBookingActions();
	const confirm = async () => {
		await confirmBooking(record.id);
		refetch();
	};
	const reject = async () => {
		await rejectBooking(record.id);
		refetch();
	};
	const moreMenu = (record: IBooking) => (
		<Menu
			mode="vertical"
			onClick={({ domEvent }) => domEvent.stopPropagation()}
		>
			<Menu.Item
				key="accept"
				style={{
					fontSize: 15,
					display: "flex",
					alignItems: "center",
					fontWeight: 500,
				}}
				disabled={record.status != EBookingStatus.PaymentPending}
				icon={
					<Icons.CheckCircleOutlined
						style={{
							color: "#52c41a",
							fontSize: 17,
							fontWeight: 500,
						}}
					/>
				}
				onClick={confirm}
			>
				Confirm
			</Menu.Item>
			<Menu.Item
				key="reject"
				style={{
					fontSize: 15,
					display: "flex",
					alignItems: "center",
					fontWeight: 500,
				}}
				icon={
					<Icons.CloseCircleOutlined
						style={{
							color: "#EE2A1E",
							fontSize: 17,
						}}
					/>
				}
				disabled={record.status != EBookingStatus.PaymentPending}
				onClick={reject}
			>
				Reject
			</Menu.Item>
		</Menu>
	);
	return (
		<Dropdown overlay={moreMenu(record)} trigger={["click"]}>
			<Icons.MoreOutlined
				onClick={(e) => e.stopPropagation()}
				style={{
					fontSize: 24,
				}}
			/>
		</Dropdown>
	);
};
