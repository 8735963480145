import {
	Button,
	Col,
	Divider,
	Form,
	Icons,
	Input,
	Modal,
	notification,
	Row,
	Select,
} from "@pankod/refine-antd";
import { IAmenity, IProperty, IRoom, IRoomType } from "interfaces";
import moment from "moment";
import { useState } from "react";
import { axiosInstance } from "services/axios-instance";
declare type IRoomFormModalPropsType = {
	property: IProperty;
	currentRoom: IRoom;
	amenityList: IAmenity[] | undefined;
	roomTypeList: IRoomType[] | undefined;
	getRoomTypeLoading: boolean;
	refetchProperty: () => void;
	children: React.ReactNode;
};
// const { RangePicker } = DatePicker;
const RoomFormModal = (props: IRoomFormModalPropsType) => {
	const {
		property,
		currentRoom,
		roomTypeList,
		amenityList,
		getRoomTypeLoading,
		refetchProperty,
		children,
	} = props;
	const [isRoomEditVisible, setIsRoomEditVisible] = useState(false);
	const [isRoomUpdateLoading, setIsRoomUpdateLoading] = useState(false);

	const onUpdatePropertyRoom = () => {
		form
			.validateFields()
			.then(async (room_form) => {
				console.log("room_form", room_form);
				room_form.pricing = room_form.pricing || [];
				// check start date and end date format
				let validDateFormat = true;
				if (room_form.pricing.length > 0) {
					room_form.pricing.forEach((pricing) => {
						const validStart = moment(
							pricing.startDate,
							"YYYY-MM-DD",
							true
						).isValid();
						const validEnd = moment(
							pricing.endDate,
							"YYYY-MM-DD",
							true
						).isValid();
						if (!validStart || !validEnd) {
							validDateFormat = false;
							return;
						}
					});
				}
				if (!validDateFormat) {
					notification.error({
						message: "Invalid Date Format",
						description: "Please enter valid date format",
					});
					return;
				}

				// room_form.pricing.forEach((item) => {
				// 	//@ts-ignore
				// 	const dateRange = item.dateRange as RangeValue<dayjs.Dayjs>;
				// 	item.startDate = dateRange[0].format("YYYY-MM-DD");
				// 	item.endDate = dateRange[1].format("YYYY-MM-DD");
				// });
				setIsRoomUpdateLoading(true);
				let rooms_form = property.rooms;
				if (room_form.id) {
					rooms_form = rooms_form.map((room) => {
						if (room.id === room_form.id) {
							return {
								...room,
								...room_form,
							};
						}
						return room;
					});
				} else {
					room_form.roomSatus = 1;
					rooms_form.push(room_form);
				}

				const data: IProperty = {
					...property,
					rooms: rooms_form,
				};
				await axiosInstance
					.post("/property", data)
					.then((res) => {
						if (res.status === 200) {
							refetchProperty();
							notification.success({
								message: "Success",
								description: "Room updated successfully",
							});
							onClose();
						}
					})
					.catch((err) => {
						console.log(err);
						notification.error({
							message: "Error",
							description: err.message,
						});
					})
					.finally(() => {
						setIsRoomUpdateLoading(false);
					});
			})
			.catch((e) => {
				setIsRoomUpdateLoading(false);
				console.log("form error", e);
			});
	};
	const onOpen = () => {
		setIsRoomEditVisible(true);
	};
	const onClose = () => {
		if (isRoomUpdateLoading) return;
		setIsRoomEditVisible(false);
		form.resetFields();
	};

	const [form] = Form.useForm<IRoom>();

	return (
		<>
			<div onClick={onOpen}>{children}</div>
			<Modal
				title="Edit Room"
				visible={isRoomEditVisible}
				onOk={onUpdatePropertyRoom}
				onCancel={onClose}
				cancelButtonProps={{ onClick: onClose }}
				keyboard={false}
				maskClosable={false}
				destroyOnClose
				okButtonProps={{
					ghost: true,
					loading: isRoomUpdateLoading,
				}}
				width={1200}
			>
				<Form layout="vertical" form={form} initialValues={currentRoom}>
					<Form.Item name="id" noStyle>
						<Input type="hidden" />
					</Form.Item>
					<Form.Item name="roomSatus" noStyle>
						<Input type="hidden" />
					</Form.Item>

					<Row gutter={15}>
						<Col span={6}>
							<Form.Item
								label="Room Type"
								name="roomTypeID"
								rules={[
									{
										required: true,
										message: "Please select room type!",
									},
								]}
							>
								<Select
									placeholder="Select Room Type"
									disabled={getRoomTypeLoading}
								>
									{roomTypeList?.map((item) => (
										<Select.Option key={item.id} value={item.id}>
											{item.name}
										</Select.Option>
									))}
								</Select>
							</Form.Item>
						</Col>
						<Col span={4}>
							<Form.Item
								label="Number of People"
								name="numPersons"
								rules={[
									{
										required: true,
										message: "Please input number of people!",
									},
								]}
							>
								<Input
									type="number"
									placeholder="Number of People"
									min={1}
									max={10}
									step={1}
								/>
							</Form.Item>
						</Col>
						<Col span={4}>
							<Form.Item
								label="Number of Rooms"
								name="numRooms"
								rules={[
									{
										required: true,
										message: "Please input number of room!",
									},
								]}
							>
								<Input
									type="number"
									placeholder="Number of Rooms"
									min={1}
									max={1000}
									step={1}
								/>
							</Form.Item>
						</Col>
						<Col span={10}>
							<Form.Item
								label="Room Description"
								name="description"
								rules={[
									{
										required: true,
										message: "Please input room description!",
									},
								]}
							>
								<Input placeholder="Room Description" allowClear />
							</Form.Item>
						</Col>
					</Row>

					<Divider />
					<h1 className="text-base font-semibold text-blue-700 pb-5">
						Price Configuration
					</h1>

					<Form.List name="pricing">
						{(fields, { add, remove }) => {
							return (
								<div>
									{fields.map((field) => (
										<Row gutter={15} key={field.key}>
											{/* <Form.Item
												name={[field.name, "dateRange"]}
												label="Date Range"
												rules={[
													{
														type: "array" as const,
														required: true,
														message: "Please select time!",
													},
												]}
											>
												<RangePicker
													format={"YYYY-MM-DD"}
													// @ts-ignore
													defaultValue={getRangeDateValueByPriceId(field.name)}
												/>
											</Form.Item> */}
											<Col span={6}>
												<Form.Item
													label="Start Date"
													name={[field.name, "startDate"]}
													rules={[
														{
															required: true,
															message: "Please input start date!",
														},
													]}
												>
													<Input placeholder="Start Date YYYY-MM-DD" />
												</Form.Item>
											</Col>
											<Col span={6}>
												<Form.Item
													label="End Date"
													name={[field.name, "endDate"]}
													rules={[
														{
															required: true,
															message: "Please input end date!",
														},
													]}
												>
													<Input placeholder="End Date YYYY-MM-DD" />
												</Form.Item>
											</Col>
											<Col span={4}>
												<Form.Item
													label="Adult Price"
													name={[field.name, "adultPrice"]}
													rules={[
														{
															required: true,
															message: "Please input price!",
														},
													]}
												>
													<Input
														type="number"
														placeholder="Price (USD)"
														min={1}
														max={1000}
														step={1}
													/>
												</Form.Item>
											</Col>
											<Col span={4}>
												<Form.Item
													label="Child Price"
													name={[field.name, "childPrice"]}
													rules={[
														{
															required: true,
															message: "Please input price!",
														},
													]}
												>
													<Input
														type="number"
														placeholder="Price (USD)"
														min={1}
														max={1000}
														step={1}
													/>
												</Form.Item>
											</Col>
											<Col span={3}>
												<Form.Item
													label="Infant Price"
													name={[field.name, "infantPrice"]}
													rules={[
														{
															required: true,
															message: "Please input price!",
														},
													]}
												>
													<Input
														type="number"
														placeholder="Price (USD)"
														min={1}
														max={1000}
														step={1}
													/>
												</Form.Item>
											</Col>

											<Col span={1}>
												<Icons.MinusCircleOutlined
													className="mt-10"
													onClick={() => {
														remove(field.name);
													}}
													style={{
														color: "red",
														cursor: "pointer",
													}}
												/>
											</Col>
										</Row>
									))}
									<Form.Item>
										<Button
											type="dashed"
											onClick={() => {
												add();
											}}
											block
										>
											<div className="flex justify-center items-center space-x-2">
												<Icons.PlusOutlined /> <p>Add Price Configuration</p>
											</div>
										</Button>
									</Form.Item>
								</div>
							);
						}}
					</Form.List>
					<Divider />
					<Form.Item
						label="Amenities"
						name="aminities"
						rules={[
							{
								required: true,
								message: "Please input the amenities!",
							},
						]}
					>
						<Select
							mode="multiple"
							allowClear
							style={{ width: "100%" }}
							loading={getRoomTypeLoading}
							placeholder="Please select amenities"
							onChange={() => {}}
							options={amenityList?.map((amenity) => {
								return {
									label: amenity.name,
									value: amenity.id,
								};
							})}
						/>
					</Form.Item>
				</Form>
			</Modal>
		</>
	);
};

export default RoomFormModal;
