import { Button, Form, Input } from "@pankod/refine-antd";
import { useNotification } from "@pankod/refine-core";
import { useState } from "react";
import { axiosInstance } from "services/axios-instance";

type propsType = {
	setPageTitle: Function;
};

export default function ForgetPasswordComponent({ setPageTitle }: propsType) {
	const [isLoading, setIsLoading] = useState(false);
	const [disable, setDisable] = useState(false);
	const notify = useNotification();
	const forgetPassword = async (form: any) => {
		setIsLoading(true);
		try {
			const res = await axiosInstance.post("/partner/recovery", form);
			setIsLoading(false);
			notify.open &&
				notify.open({
					message: res.data.message,
					type: "success",
					undoableTimeout: 2000,
				});
			setDisable(true);
			setTimeout(() => {
				window.location.reload();
			}, 3000);
		} catch (error: any) {
			notify.open &&
				notify.open({
					message: error.response.data.message,
					type: "error",
					undoableTimeout: 3000,
				});
			setIsLoading(false);
		}
	};
	return (
		<Form
			id="forgetPW"
			onSubmitCapture={(e) => e.preventDefault()}
			onFinish={(e) => forgetPassword(e)}
			layout="vertical"
			disabled={disable}
		>
			<Form.Item
				name="email"
				rules={[
					{
						required: true,
						message: "Email cannot be empty",
					},
				]}
			>
				<Input
					className="p-2 focus:bg-none"
					size="middle"
					placeholder="Email"
				/>
			</Form.Item>
			<Form.Item className="login-button">
				<Button
					type="primary"
					htmlType="submit"
					className="py-1 h-fit font-medium text-white
                                w-full border-2 bg-primary box-border
                                hover:bg-white hover:text-primary hover:border-primary
                                "
					loading={isLoading}
				>
					Reset
				</Button>
			</Form.Item>
			<Form.Item>
				<div className="w-full flex justify-center items-center">
					<div
						tabIndex={0}
						onClick={() => setPageTitle("Login")}
						className="p-2 cursor-pointer text-primary hover:text-medium"
					>
						Already Have Account?
					</div>
				</div>
			</Form.Item>
		</Form>
	);
}
