import {
	Col,
	Create,
	Divider,
	Form,
	Input,
	Row,
	Select,
	useForm,
} from "@pankod/refine-antd";
import { useRequest } from "ahooks";
import { ICity, ICountry, IPropertyInputDto } from "interfaces";
import { useState } from "react";
import { axiosInstance } from "services/axios-instance";
import { useGetCountryList } from "utils/hooks";

const PropertyCreate = () => {
	const { formProps, saveButtonProps } = useForm<IPropertyInputDto>();
	const [showSelectCity, setShowSelectCity] = useState<boolean>(false);

	const { data: countries, loading: getCountriesLoading } = useGetCountryList();
	const getCityService = async (countryId: number) => {
		const res = await axiosInstance.get(`/cities/${countryId || 1}`);

		return res.data.map((city: ICity) => ({
			label: city.name,
			value: city.id,
		}));
	};
	const {
		runAsync: getCityList,
		data: cities,
		loading: getCitiesLoading,
	} = useRequest(getCityService, {
		cacheTime: 30 * 6000,
	});

	return (
		<Create
			saveButtonProps={{
				...saveButtonProps,
				ghost: true,
			}}
		>
			<Form {...formProps} layout="vertical">
				<Row gutter={20}>
					<Col span={8}>
						<Form.Item
							label="Name"
							name="name"
							rules={[{ required: true, message: "Please input name!" }]}
						>
							<Input placeholder="Enter name" />
						</Form.Item>
					</Col>
					<Col span={8}>
						<Form.Item
							label="Email"
							name="email"
							rules={[{ required: true, message: "Please input email!" }]}
						>
							<Input type="email" placeholder="Enter email" />
						</Form.Item>
					</Col>
					<Col span={8}>
						<Form.Item
							label="Phone"
							name="phone"
							rules={[{ required: true, message: "Please input phone!" }]}
						>
							<Input placeholder="Enter phone" />
						</Form.Item>
					</Col>
				</Row>
				<Divider />
				{/* Address */}
				<Row gutter={20}>
					<Col span={12}>
						<Form.Item
							label="Address One"
							name="addressOne"
							rules={[{ required: true, message: "Please input address!" }]}
						>
							<Input placeholder="Enter Address" />
						</Form.Item>
					</Col>
					<Col span={12}>
						<Form.Item label="Address Two" name="addressTwo">
							<Input placeholder="Enter Address (optional)" />
						</Form.Item>
					</Col>
				</Row>
				<Row gutter={20}>
					<Col span={8}>
						<Form.Item
							label="Country"
							name="countryID"
							rules={[{ required: true, message: "Please input countryID!" }]}
						>
							<Select
								onSelect={(v: number) => {
									setShowSelectCity(false);
									getCityList(v).then((res) => {
										setShowSelectCity(true);
										console.log("onSelect", res);
									});
								}}
								loading={getCountriesLoading}
								placeholder="Select a country"
								options={countries?.map((country: ICountry) => ({
									label: country.name,
									value: country.id,
								}))}
							/>
						</Form.Item>
					</Col>
					<Col span={8}>
						<Form.Item
							label="City"
							name="cityID"
							rules={[{ required: true, message: "Please input cityID!" }]}
						>
							<Select
								disabled={!showSelectCity}
								loading={getCitiesLoading}
								placeholder="Select a city"
								options={cities}
							/>
						</Form.Item>
					</Col>
					<Col span={8}>
						<Form.Item
							label="Postal Code"
							name="postalCode"
							rules={[{ required: true, message: "Please input postalCode!" }]}
						>
							<Input placeholder="Enter postal code" />
						</Form.Item>
					</Col>
				</Row>
				<Row gutter={20}>
					<Col span={12}>
						<Form.Item label="Latitude" name="locationLat">
							<Input placeholder="Map Latitude" />
						</Form.Item>
					</Col>
					<Col span={12}>
						<Form.Item label="Longitude" name="locationLon">
							<Input placeholder="Map Longitude" />
						</Form.Item>
					</Col>
				</Row>
				<Row gutter={20}>
					<Col span={8}>
						<Form.Item hidden initialValue={[]} name="rooms"></Form.Item>
					</Col>
					<Col span={8}>
						<Form.Item
							hidden
							initialValue={1}
							label="propertyTypeID"
							name="propertyTypeID"
						>
							<Input type="number" />
						</Form.Item>
					</Col>
					<Col span={8}>
						<Form.Item
							hidden
							initialValue={1}
							label="propertyStatus"
							name="properyStatus"
						>
							<Input type="number" />
						</Form.Item>
					</Col>
				</Row>
			</Form>
		</Create>
	);
};
export default PropertyCreate;
