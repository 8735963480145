import {
	CrudFilters,
	HttpError,
	getDefaultFilter,
	useExport,
	useNavigation,
} from "@pankod/refine-core";

import {
	Button,
	Card,
	Col,
	DateField,
	DatePicker,
	ExportButton,
	Form,
	FormProps,
	Icons,
	Input,
	List,
	NumberField,
	Row,
	Select,
	Table,
	TextField,
	useTable,
} from "@pankod/refine-antd";
import { BookingActions, BookingStatus } from "components";
import dayjs from "dayjs";
import React, { useMemo } from "react";
import { useGetStatusBookings } from "utils/hooks";
import { BookingFilterProps, IBooking } from "../../interfaces";

const BookingList = () => {
	const { tableProps, sorter, searchFormProps, filters, tableQueryResult } =
		useTable<IBooking, HttpError, BookingFilterProps>({
			hasPagination: true,
			initialPageSize: 50,
			queryOptions: {
				refetchInterval: 10000,
			},
			onSearch: (params) => {
				const filters: CrudFilters = [];
				const { date, customer, status } = params;
				filters.push(
					{
						field: "checkin_from",
						operator: "null",
						value: date ? date[0].startOf("day").toISOString() : undefined,
					},
					{
						field: "checkin_to",
						operator: "null",
						value: date ? date[1].endOf("day").toISOString() : undefined,
					},
					{
						field: "customer",
						operator: "null",
						value: customer,
					},
					{
						field: "status",
						operator: "null",
						value: status,
					}
				);

				return filters;
			},

			dataProviderName: "default",
			resource: "supplier/booking/list",
		});
	const {
		data,
		refetch,
		isLoading: tableLoading,
		isFetching,
	} = tableQueryResult;
	const { show } = useNavigation();

	const { isLoading, triggerExport } = useExport<IBooking>({
		dataProviderName: "default",
		resourceName: "supplier/booking/list",
		sorter,
		filters,
		pageSize: 50,
		maxItemCount: 2,
		mapData: (booking) => {
			return {
				id: booking.id,
				bookedByGuest: booking.bookedByGuest,
				checkinDate: booking.checkinDate,
				checkoutDate: booking.checkoutDate,
				guestDocumentNumber: booking.guestDocumentNumber,
				guestDocumentType: booking.guestDocumentType,
				guestFirstName: booking.guestFirstName,
				guestLastName: booking.guestLastName,
				status: booking.status,
			};
		},
	});

	const Actions: React.FC = () => (
		<Row gutter={8}>
			<Col>
				<Button loading={isFetching} onClick={() => refetch()}>
					Reload
				</Button>
			</Col>
			<Col>
				<ExportButton onClick={triggerExport} loading={isLoading} />
			</Col>
		</Row>
	);
	return (
		<Row gutter={[16, 16]}>
			<Col span={24}>
				<Card
					bodyStyle={{
						paddingBottom: 0,
					}}
					bordered={false}
					title={<div className="text-xl text-primary font-bold">Filter</div>}
				>
					<Filter formProps={searchFormProps} filters={filters || []} />
				</Card>
			</Col>
			<Col span={24} className="pb-14">
				<List
					title={<span className="text-primary">Recent Bookings</span>}
					pageHeaderProps={{
						extra: <Actions />,
					}}
				>
					<Table
						{...tableProps}
						pagination={{
							...tableProps.pagination,
							showSizeChanger: false,
						}}
						dataSource={data?.data}
						loading={tableLoading}
						rowKey="id"
						onRow={(record) => {
							return {
								onClick: () => {
									show("supplier/booking", record.id);
								},
								className: "cursor-pointer",
							};
						}}
					>
						{/*booking id*/}
						<Table.Column
							title="Booking ID"
							key="id"
							dataIndex="id"
							render={(value) => <TextField value={value} />}
						/>
						{/*created at*/}
						<Table.Column
							title="Created at"
							key="createdAt"
							dataIndex="createdAt"
							render={(value) => <DateField value={value} format="LLL" />}
						/>
						{/*property name*/}
						<Table.Column
							title="Property Name"
							key="propertyName"
							dataIndex={["property", "name"]}
							render={(value) => <DateField value={value} format="LLL" />}
						/>
						{/*customer name*/}
						<Table.Column
							title="Customer Name"
							key="customerName"
							render={(r) => (
								<span>{r.guestFirstName + " " + r.guestLastName}</span>
							)}
						/>
						{/*phone number*/}
						<Table.Column
							title="Customer Phone number"
							key="guestPhone"
							dataIndex="guestPhone"
							render={(r) => <span>{r}</span>}
						/>
						{/*Room*/}
						<Table.Column
							title="Room"
							key="numRooms"
							dataIndex="numRooms"
							render={(r) => <span>{r}</span>}
						/>
						{/*Check in*/}
						<Table.Column
							title="Check-in Date"
							key="checkinDate"
							dataIndex="checkinDate"
							render={(value) => <DateField value={value} format="LLL" />}
							sorter
						/>
						{/*Check out*/}
						<Table.Column
							title="Check-out Date"
							key="checkoutDate"
							dataIndex="checkoutDate"
							render={(value) => <DateField value={value} format="LLL" />}
						/>
						{/*amount*/}
						<Table.Column
							title="Amount"
							key="amount"
							dataIndex="billed_amount"
							render={(value) => (
								<NumberField
									options={{
										currency: "USD",
										style: "currency",
										notation: "standard",
									}}
									value={value ?? 0}
								/>
							)}
						/>
						{/*status*/}
						<Table.Column<IBooking>
							key="statusName"
							dataIndex="statusCode"
							title="Status"
							render={(value) => {
								return <BookingStatus status={value} />;
							}}
						/>
						{/*action button*/}
						<Table.Column<IBooking>
							fixed="right"
							title="Action"
							dataIndex="actions"
							key="actions"
							align="center"
							render={(_value, record) => (
								<BookingActions refetch={refetch} record={record} />
							)}
						/>
					</Table>
				</List>
			</Col>
		</Row>
	);
};

const Filter: React.FC<{ formProps: FormProps; filters: CrudFilters }> = (
	props
) => {
	const { formProps, filters } = props;
	// const { selectProps: storeSelectProps } = useSelect<IStore>({
	// 	resource: "stores",
	// 	defaultValue: getDefaultFilter("store.id", filters),
	// 	dataProviderName: "test",
	// });

	const { data: selectStatus, loading: statusSelectLoading } =
		useGetStatusBookings();

	const { RangePicker } = DatePicker;

	const createdAt = useMemo(() => {
		const start = getDefaultFilter("createdAt", filters, "gte");
		const end = getDefaultFilter("createdAt", filters, "lte");

		const startFrom = dayjs(start);
		const endAt = dayjs(end);

		if (start && end) {
			return [startFrom, endAt];
		}
		return undefined;
	}, [filters]);

	const labelText = (text: string) => (
		<div className="text-base text-black font-bold">{text}</div>
	);

	return (
		<Form
			layout="vertical"
			{...formProps}
			initialValues={{
				// q: getDefaultFilter("q", filters),
				// store: getDefaultFilter("store.id", filters),
				// status: getDefaultFilter("status.text", filters, "in"),
				createdAt,
			}}
		>
			<Row gutter={[30, 0]} align="top">
				<Col span={24}>
					<Form.Item label={labelText("Search")} name="q">
						<Input
							placeholder="Bookings, Customer Name,..."
							prefix={<Icons.SearchOutlined />}
						/>
					</Form.Item>
				</Col>
				<Col span={12}>
					<Form.Item label={labelText("Customer Name")} name="customer">
						<Input placeholder="Search customer" />
					</Form.Item>
				</Col>
				{/* <Col span={12}>
					<Form.Item label={labelText("Property")} name="store">
						<Select
							{...storeSelectProps}
							allowClear
							placeholder="Search property"
						/>
					</Form.Item>
				</Col> */}
				<Col span={12}>
					<Form.Item label={labelText("Date")} name="date">
						<RangePicker
							placeholder={["Check-in", "Check-out"]}
							style={{ width: "100%" }}
						/>
					</Form.Item>
				</Col>
				<Col span={12}>
					<Form.Item label={labelText("Status")} name="status">
						<Select
							loading={statusSelectLoading}
							options={selectStatus?.map((item) => ({
								label: item.name,
								value: item.code,
							}))}
							allowClear
							placeholder="Search status"
						/>
					</Form.Item>
				</Col>
				<Col span={24}>
					<Form.Item>
						<div className="w-full flex justify-end">
							<Button
								style={{
									width: "15%",
									alignContent: "end",
								}}
								htmlType="submit"
								size="large"
								className="bg-green-500 text-white text-lg"
								block
							>
								Filter
							</Button>
						</div>
					</Form.Item>
				</Col>
			</Row>
		</Form>
	);
};

export default BookingList;
