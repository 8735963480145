import {
	Col,
	Form,
	Input,
	Modal,
	notification,
	Row,
} from "@pankod/refine-antd";
import { IPasswordChangeDto } from "interfaces";
import { useState } from "react";
import { axiosInstance } from "services/axios-instance";

declare type UserPasswordModalPropsType = {
	user: IPasswordChangeDto;
	refetchProfile: () => void;
	children: React.ReactNode;
};

const UserPasswordFormModal = (props: UserPasswordModalPropsType) => {
	const { user, refetchProfile, children } = props;

	const [form] = Form.useForm();
	const [showPasswordChangeForm, setShowPasswordChangeForm] =
		useState<boolean>(false);
	const onOpen = () => {
		setShowPasswordChangeForm(true);
	};
	const onClose = () => {
		setShowPasswordChangeForm(false);
	};

	const [changePasswordLoading, setChangePasswordLoading] =
		useState<boolean>(false);
	const onUpdate = () => {
		form
			.validateFields()
			.then((passwordForm) => {
				setChangePasswordLoading(true);
				if (
					!passwordForm.newPassword ||
					!passwordForm.confirmPassword ||
					!passwordForm.currentPassword
				) {
					notification.error({
						message: "Error",
						description: "Please fill all fields",
					});
					setChangePasswordLoading(false);
					return;
				}
				if (passwordForm.newPassword.length < 8) {
					notification.error({
						message: "Error",
						description: "Password must be at least 8 characters",
					});
					setChangePasswordLoading(false);
					return;
				}
				if (passwordForm.newPassword !== passwordForm.confirmPassword) {
					notification.error({
						message: "Error",
						description: "New password and confirm password does not match",
					});
					setChangePasswordLoading(false);
					return;
				}
				const data = {
					...passwordForm,
				};
				axiosInstance
					.post("/supplier/changepw", data)
					.then((res) => {
						setChangePasswordLoading(false);
						if (res.status === 200) {
							notification.success({
								message: "Success",
								description: "Password changed successfully",
							});

							refetchProfile();
							onClose();
						}
					})
					.catch((err) => {
						notification.error({
							message: "Error",
							description: err.message,
						});
						setChangePasswordLoading(false);
						console.log(err);
					});
			})
			.catch((info) => {
				console.log("Validate Failed:", info);
			});
	};

	return (
		<div>
			<div onClick={onOpen}>{children}</div>
			<Modal
				visible={showPasswordChangeForm}
				okText="Update"
				cancelText="Cancel"
				okButtonProps={{ ghost: true, loading: changePasswordLoading }}
				onOk={onUpdate}
				onCancel={onClose}
				keyboard={false}
				closable
				title="Change Password"
				width={1200}
			>
				<Form form={form} initialValues={user}>
					<Row gutter={20}>
						<Col span={12}>
							<Form.Item label="Current Password" name="currentPassword">
								<Input.Password placeholder="current password" size="large" />
							</Form.Item>
						</Col>
					</Row>
					<Row gutter={20}>
						<Col span={12}>
							<Form.Item label="New Password" name="newPassword">
								<Input.Password
									placeholder="new password"
									type="password"
									size="large"
								/>
							</Form.Item>
						</Col>
					</Row>
					<Row>
						<Col span={12}>
							<Form.Item label="Confirm new password" name="confirmPassword">
								<Input.Password
									placeholder="same as new password"
									size="large"
								/>
							</Form.Item>
						</Col>
					</Row>
				</Form>
			</Modal>
		</div>
	);
};

export default UserPasswordFormModal;
