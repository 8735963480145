/* eslint-disable array-callback-return */
import axios, { AxiosInstance } from "axios";
import { BASE_API_URL } from "constants/index";
import {
	CrudFilters,
	CrudOperators,
	CrudSorting,
	DataProvider,
} from "interfaces/DataProvider";
import { stringify } from "query-string";

const axiosInstance = axios.create();

export const RevDataProvider = (
	apiUrl: string = BASE_API_URL,
	httpClient: AxiosInstance = axiosInstance
): DataProvider => ({
	create: async ({ resource, variables, metaData }) => {
		const url = `${apiUrl}/${resource}`;

		const { data } = await httpClient.post(url, variables);

		return {
			data,
		};
	},
	createMany: async ({ resource, variables }) => {
		const response = await Promise.all(
			variables.map(async (param) => {
				const { data } = await httpClient.post(`${apiUrl}/${resource}`, param);
				return data;
			})
		);

		return { data: response };
	},
	deleteOne: async ({ resource, id }) => {
		const url = `${apiUrl}/${resource}/delete?id=${id}`;

		const { data } = await httpClient.get(url);

		return {
			data,
		};
	},
	deleteMany: async ({ resource, ids }) => {
		const response = await Promise.all(
			ids.map(async (id) => {
				const { data } = await httpClient.delete(`${apiUrl}/${resource}/${id}`);
				return data;
			})
		);
		return { data: response };
	},
	getList: async ({
		resource,
		hasPagination = true,
		pagination,
		filters,
		sort,
	}) => {
		const url = `${apiUrl}/${resource}`;

		const current = pagination?.current || 1;
		const pageSize = pagination?.pageSize || 50;

		const { sort: sorts, order } = generateSort(sort);

		const queryFilters = generateFilter(filters);

		const query = {
			...(hasPagination
				? {
						start: (current - 1) * pageSize,
						end: current * pageSize,
						page: current,
				  }
				: {}),
			sorts: sorts.join(","),
			order: order.join(","),
		};

		const { data } = await httpClient.get(
			`${url}?${stringify(query)}&${stringify(queryFilters)}`
		);
		return {
			data: data.data,
			total: data?.meta?.total,
		};
	},
	getMany: async ({ resource, ids }) => {
		const { data } = await httpClient.get(
			// @ts-ignore
			`${apiUrl}/${resource}?${stringify({ id: ids })}`
		);

		return {
			data,
		};
	},
	getOne: async ({ resource, id }) => {
		const url = `${apiUrl}/${resource}/show/${id}`;

		const { data } = await httpClient.get(url);

		return {
			data,
		};
	},
	update: async ({ resource, id, variables }) => {
		const url = `${apiUrl}/${resource}/${id}`;

		const { data } = await httpClient.patch(url, variables);

		return {
			data,
		};
	},
	updateMany: async ({ resource, ids, variables }) => {
		const response = await Promise.all(
			ids.map(async (id) => {
				const { data } = await httpClient.patch(
					`${apiUrl}/${resource}/${id}`,
					variables
				);
				return data;
			})
		);

		return { data: response };
	},
	custom: async ({ url, method, filters, sort, payload, query, headers }) => {
		let requestUrl = `${url}?`;

		if (sort) {
			const { sort: sorts, order } = generateSort(sort);
			const sortQuery = {
				sorts: sorts.join(","),
				order: order.join(","),
			};
			requestUrl = `${requestUrl}&${stringify(sortQuery)}`;
		}

		if (filters) {
			const filterQuery = generateFilter(filters);
			requestUrl = `${requestUrl}&${stringify(filterQuery)}`;
		}

		if (query) {
			requestUrl = `${requestUrl}&${stringify(query)}`;
		}

		if (headers) {
			httpClient.defaults.headers = {
				...httpClient.defaults.headers,
				...headers,
			};
		}

		let axiosResponse;
		switch (method) {
			case "put":
			case "post":
			case "patch":
				axiosResponse = await httpClient[method](url, payload);
				break;
			case "delete":
				axiosResponse = await httpClient.delete(url);
				break;
			default:
				axiosResponse = await httpClient.get(requestUrl);
				break;
		}

		const { data } = axiosResponse;

		return Promise.resolve({ data });
	},
	getApiUrl: () => "",
});

const generateSort = (sortField?: CrudSorting) => {
	let sort = ["id"]; // default sorting field
	let order = ["desc"]; // default sorting

	if (sortField) {
		sort = [];
		order = [];

		sortField.map((item) => {
			sort.push(item.field);
			order.push(item.order);
		});
	}

	return {
		sort,
		order,
	};
};
const mapOperator = (operator: CrudOperators): string => {
	switch (operator) {
		case "ne":
		case "gte":
		case "lte":
			return `_${operator}`;
		case "contains":
			return "_like";
	}

	return ""; // default "eq"
};

const generateFilter = (filters?: CrudFilters) => {
	const queryFilters: { [key: string]: string } = {};
	if (filters) {
		// @ts-ignored
		filters.map(({ field, operator, value }) => {
			const mappedOperator = mapOperator(operator);
			queryFilters[`${field}${mappedOperator}`] = value;
		});
	}

	return queryFilters;
};
