import { Descriptions } from "@pankod/refine-antd";
import { IRoomPrice } from "interfaces";

const RoomPriceConfig = ({ item }: { item: IRoomPrice }) => (
	<Descriptions
		contentStyle={{
			fontSize: "0.8rem",
		}}
		className="pb-8"
		key={item.id}
		size="small"
		bordered
		title={
			<p className="text-sm">
				{item.startDate} - {item.endDate}
			</p>
		}
		labelStyle={{ fontWeight: "500" }}
	>
		<Descriptions.Item label="Adult Price">
			${item.adultPrice}
		</Descriptions.Item>
		<Descriptions.Item label="Child Price">
			${item.childPrice}
		</Descriptions.Item>
		<Descriptions.Item label="Infant Price">
			${item.infantPrice}
		</Descriptions.Item>
	</Descriptions>
);

export default RoomPriceConfig;
