import axios, { AxiosInstance, AxiosRequestConfig } from "axios";
import { GetTokenFromCookie } from "./cookie-services";

export let axiosInstance: AxiosInstance;

export function initializeAxiosInstance() {
    axiosInstance = axios.create();

    // base api url
    axiosInstance.defaults.baseURL = 'https://kavianlanka.com/resv/v1/api';

    //setting token to header if exist
    axiosInstance.interceptors.request.use(
        // Here we can perform any function we'd like on the request
        (request: AxiosRequestConfig) => {
            // Retrieve the token from local storage
            const token = GetTokenFromCookie();
            // Check if the header property exists
            if (request.headers) {
                // Set the Authorization header if it exists
                request.headers[
                    "Authorization"
                ] = `Bearer ${token}`;
            } else {
                // Create the headers property if it does not exist
                request.headers = {
                    Authorization: `Bearer ${token}`,
                };
            }

            return request;
        },
    );


    return axiosInstance;
}
