import { userRoles } from "../constants/index";
import { axiosInstance } from "./axios-instance";
import {
	DeleteTokenFromCookie,
	GetTokenFromCookie,
	SaveTokenToCookie,
} from "./cookie-services";

const loginRequest = async ({ email, password }: loginInfo) =>
	await axiosInstance
		.post("/login", {
			email,
			password,
		})
		.then((res) => {
			return {
				...res.data,
				isLoggedIn: true,
			};
		})
		.catch((error) => {
			if (error.response.status === 422) {
				if (error.response.data.message) {
					return {
						errorMessage: error.response.data.message,
						isLoggedIn: false,
					};
				} else if (error.response.data.errors) {
					return {
						errorMessage: error.response.data.errors.join(" "),
						isLoggedIn: false,
					};
				}
			} else {
				return {
					isLoggedIn: false,
				};
			}
		});

const signupRequest = async ({
	firstName,
	lastName,
	email,
	password,
	password_confirmation,
	isRegistering,
}: loginInfo) => {
	if (password !== password_confirmation) {
		return {
			errorMessage: "The password confirmation does not match.",
			isLoggedIn: false,
		};
	}

	return await axiosInstance
		.post("/register", {
			firstName,
			lastName,
			email,
			password,
			password_confirmation,
		})
		.then((res) => {
			return {
				...res.data,
				isLoggedIn: true,
			};
		})
		.catch((error) => {
			if (error.response.status === 422) {
				if (error.response.data.message) {
					return {
						errorMessage: error.response.data.message,
						isLoggedIn: false,
					};
				} else if (error.response.data.errors) {
					return {
						errorMessage: error.response.data.errors.join(" "),
						isLoggedIn: false,
					};
				}
			} else {
				return {
					isLoggedIn: false,
				};
			}
		});
};

const login = async (values: loginInfo) => {
	// making api request for token
	const response: apiResponse = !values.isRegistering
		? await loginRequest(values)
		: await signupRequest(values);

	// login validation
	if (!response.isLoggedIn) {
		return Promise.reject({
			name: "Login failed!!!",
			message: response.errorMessage,
		});
	} else if (!response.token) {
		return Promise.reject({
			name: "Login failed!!!",
			message: "Internal server error",
		});
	}

	// retrieving login token and save to cookie
	SaveTokenToCookie(response.token);

	// setting token to axios default header value
	axiosInstance.defaults.headers.common = {
		Authorization: `Bearer ${response.token}`,
	};
	return Promise.resolve();
};

const logout = () => {
	DeleteTokenFromCookie();
	return Promise.resolve();
};

const checkAuth = () => {
	return GetTokenFromCookie() ? Promise.resolve() : Promise.reject();
};

const getPermissions = () => {
	// TODO: get user role and identify the role when api is available
	const auth = GetTokenFromCookie();
	if (auth) {
		return Promise.resolve(userRoles.propertyOwner);
	}
	return Promise.reject();
};

const getUserIdentity = () => {
	//TODO: get user information from api when api is available
	const user = {
		name: "Property owner",
		avatar: "https://i.pravatar.cc/150?u=refine",
	};
	return Promise.resolve(user);
};

// @ts-ignore
const checkError = (error) => {
	if (error.status === 401) {
		return Promise.reject();
	}
	return Promise.resolve();
};

export const authProvider = {
	login: login,
	logout: logout,
	checkError: checkError,
	checkAuth: checkAuth,
	getPermissions: getPermissions,
	getUserIdentity: getUserIdentity,
};

type loginInfo = {
	firstName?: String;
	lastName?: String;
	email: String;
	password: String;
	password_confirmation?: String;
	isRegistering: boolean;
};

type apiResponse = {
	token?: String;
	errorMessage?: String;
	isLoggedIn: boolean;
};
