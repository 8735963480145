import {
	useTable,
	Row,
	Col,
	Table,
	Avatar,
	Card,
	NumberField,
} from "@pankod/refine-antd";

import { IBooking, IDashboard, IPost } from "../../interfaces";
import { TotalBooking, TotalRevenue } from "../../components/dashboard";
import { useCustom, useNavigation } from "@pankod/refine-core";
import { BookingStatus } from "components";

const Dashboard = () => {
	const { tableProps, tableQueryResult } = useTable<IBooking>({
		initialSorter: [
			{
				field: "id",
				order: "asc",
			},
		],
		initialCurrent: 1,
		hasPagination: true,
		initialPageSize: 5,
		dataProviderName: "default",
		resource: "supplier/booking/list",
		queryOptions: {
			refetchInterval: 10000,
		},
	});
	const { isLoading } = tableQueryResult;
	const { data } = useCustom<IDashboard>({
		url: "supplier/dashboard",
		dataProviderName: "default",
		method: "get",
	});
	const colConfig = {
		xl: 12,
		md: 12,
		xs: 24,
	};
	const { show } = useNavigation();

	return (
		<Row gutter={[16, 16]}>
			<Col {...colConfig}>
				<TotalRevenue totalRevenue={data?.data.total_revenue || 0} />
			</Col>
			<Col {...colConfig}>
				<TotalBooking
					totalBooking={data?.data.bookings || 0}
					pendingCancellations={data?.data.pending_cancellations || 0}
					upcomingBooking={data?.data.upcoming_bookings || 0}
				/>
			</Col>
			<Col span={24}>
				<Card
					title={
						<div className="text-primary font-bold text-xl">Recent Booking</div>
					}
					bodyStyle={{
						paddingTop: 1,
						paddingBottom: 50,
					}}
				>
					<Table
						{...tableProps}
						pagination={{ ...tableProps.pagination, simple: true }}
						showHeader={false}
						rowKey="id"
						onRow={(record) => {
							return {
								onClick: () => {
									show("supplier/booking", record.id);
								},
								className: "cursor-pointer",
							};
						}}
						loading={isLoading}
					>
						{/*Avatar section*/}
						<Table.Column<IPost>
							className="w-20"
							key="avatar"
							render={(_, record) => (
								<Avatar
									className="h-16 w-16"
									// src={record.image[0].url}
									src={
										"https://i.pinimg.com/originals/51/f6/fb/51f6fb256629fc755b8870c801092942.png"
									}
								/>
							)}
						/>
						<Table.Column<IBooking>
							// className="w-56 h-full"
							key="user-info"
							render={(_, record) => (
								<div>
									<div className="font-bold text-base text-black">
										{record.guestFirstName} {record.guestLastName}
									</div>
									<div className="text-xs text-black text-opacity-40 font-medium ">
										{record.guestPhone}
									</div>
									<br />
									<div className="text-lg font-bold text-black text-opacity-40">
										#{record.id}
									</div>
								</div>
							)}
						/>
						<Table.Column<IBooking>
							// className="w-56"
							key="booking-location"
							render={(_, record) => (
								<div>
									<h1 className="font-bold">Room Number{record.numRooms}</h1>
									<br />
									<div>{record?.property?.addressOne}</div>
								</div>
							)}
						/>
						<Table.Column<IBooking>
							key="booking-date"
							render={(_, record) => (
								<div>
									{record.checkinDate} to {record.checkoutDate}
								</div>
							)}
						/>
						<Table.Column<IBooking>
							key="booking-price"
							render={(_, record) => (
								<NumberField
									className="text-lg font-bold text-black"
									strong
									options={{
										currency: "USD",
										style: "currency",
										notation: "standard",
									}}
									value={record?.billed_amount ?? 0}
								/>
							)}
						/>
						<Table.Column<IBooking>
							className="w-14"
							key="booking-price"
							render={(_, record) => {
								return <BookingStatus status={record.statusCode} />;
							}}
						/>
					</Table>
				</Card>
			</Col>
		</Row>
	);
};

export default Dashboard;
