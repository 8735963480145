import React from "react";
import routerProvider from "@pankod/refine-react-router-v6";
import { TitleProps } from "@pankod/refine-core";
import LogoBrand from "components/logo";
// import { ReservaLogo } from "../../reserva-logo.d";

const { Link } = routerProvider;

export const Title: React.FC<TitleProps> = ({ collapsed }) => (
	<Link to="/">{<LogoBrand />}</Link>
);
