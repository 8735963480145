import { useShow } from "@pankod/refine-core";
import { Card, DateField, Space, Table, TextField } from "@pankod/refine-antd";
import { BookingSteps, CustomerDetail } from "components/booking";
import React from "react";

const BookingShow = () => {
	const { queryResult } = useShow({
		dataProviderName: "default",
	});
	const { data, isLoading } = queryResult;
	// const {mutate} = useUpdate();
	const record = data?.data.data;
	return (
		<Space size={20} direction="vertical" style={{ width: "100%" }}>
			{/* <BookingSteps record={record} /> */}
			{/* <CustomerDetail record={record} /> */}
			<Card
				loading={isLoading}
				headStyle={{
					borderBottom: 0,
				}}
				title={
					<span className="text-primary font-bold text-lg">
						Booking Summary
					</span>
				}
			>
				{record && (
					<Table dataSource={[record]} pagination={false}>
						{/*booking id*/}
						<Table.Column
							title="Booking ID"
							key="id"
							dataIndex="id"
							render={(value) => <TextField value={value} />}
						/>
						{/*created at*/}
						<Table.Column
							title="Created at"
							key="createdAt"
							dataIndex="createdAt"
							render={(value) => <DateField value={value} format="LLL" />}
						/>
						{/*Room*/}
						<Table.Column
							title="Room"
							key="rooms"
							render={(r) => <span>{r.rooms[0].description}</span>}
						/>
						{/*Number of guest*/}
						<Table.Column
							title="Number of guest"
							render={(r) => (
								<span>
									{r.numAdults + r.numChildren + r.numInfants}
									<p>
										Adults: {r.numAdults} | Children: {r.numChildren} | Infants:{" "}
										{r.numInfants}
									</p>
								</span>
							)}
						/>
						{/*Check in*/}
						<Table.Column
							title="Check-in Date"
							key="checkinDate"
							dataIndex="checkinDate"
							render={(value) => <DateField value={value} format="LLL" />}
							sorter
						/>
						{/*Check out*/}
						<Table.Column
							title="Check-out Date"
							key="checkoutDate"
							dataIndex="checkoutDate"
							render={(value) => <DateField value={value} format="LLL" />}
						/>
					</Table>
				)}
				<div className="w-full h-fit flex justify-center items-center mt-8 pb-8">
					<img
						src="/assets/dummyABA.png"
						alt="ABA receipt"
						className="w-auto h-96"
					/>
				</div>
			</Card>
		</Space>
	);
};

export default BookingShow;
