import {
	Col,
	Form,
	Input,
	Modal,
	notification,
	Row,
} from "@pankod/refine-antd";
import { IProfileUpdateDto } from "interfaces";
import { useState } from "react";
import { axiosInstance } from "services/axios-instance";

declare type UserProfileFormModalPropsType = {
	user: IProfileUpdateDto;
	refetchProfile: () => void;
	children: React.ReactNode;
};

const UserProfileFormModal = (props: UserProfileFormModalPropsType) => {
	const { user, refetchProfile, children } = props;

	const [form] = Form.useForm();
	const [showProfileUpdateForm, setShowProfileUpdateForm] =
		useState<boolean>(false);
	const onOpen = () => {
		setShowProfileUpdateForm(true);
	};
	const onClose = () => {
		setShowProfileUpdateForm(false);
	};

	const [updateProfileLoading, setUpdateProfileLoading] =
		useState<boolean>(false);
	const onUpdate = () => {
		form
			.validateFields()
			.then((profileForm) => {
				setUpdateProfileLoading(true);
				const data = {
					...profileForm,
				};
				axiosInstance
					.post("/supplier/profile", data)
					.then((res) => {
						setUpdateProfileLoading(false);
						if (res.status === 200) {
							// Uploading image
							// await uploadService({file,id,resource})
							notification.success({
								message: "Success",
								description: "Profile updated successfully",
							});

							refetchProfile();
							onClose();
						}
					})
					.catch((err) => {
						notification.error({
							message: "Error",
							description: err.message,
						});
						setUpdateProfileLoading(false);
						console.log(err);
					});
			})
			.catch((info) => {
				console.log("Validate Failed:", info);
			});
	};

	return (
		<div>
			<div onClick={onOpen}>{children}</div>
			<Modal
				visible={showProfileUpdateForm}
				okText="Update"
				cancelText="Cancel"
				okButtonProps={{ ghost: true, loading: updateProfileLoading }}
				onOk={onUpdate}
				onCancel={onClose}
				keyboard={false}
				closable
				title="Update Profile"
				width={1200}
			>
				<Form form={form} initialValues={user}>
					<Row gutter={20}>
						<Col span={12}>
							<Form.Item label="First name" name="firstName">
								<Input size="large" />
							</Form.Item>
						</Col>
						<Col span={12}>
							<Form.Item label="Last name" name="lastName">
								<Input size="large" />
							</Form.Item>
						</Col>
					</Row>
					<Row gutter={20}>
						<Col span={12}>
							<Form.Item label="Email" name="email">
								<Input size="large" />
							</Form.Item>
						</Col>
					</Row>
				</Form>
			</Modal>
		</div>
	);
};

export default UserProfileFormModal;
