import { IUser } from "interfaces";

export const userRoles = {
	unverifiedOwner: "unverified-owner",
	propertyOwner: "property-owner",
	superAdmin: "super-admin",
};

export const BASE_API_URL: string =
	process.env.BASE_API_URL || "https://kavianlanka.com/resv/v1/api";

export const DEMO_USER: IUser = {
	id: 1,
	firstName: "John",
	lastName: "Doe",
	email: "jonhdoe@email.com",
	phone: "+85516898789",
	countryID: 1,
	cityID: 1,
	password: "123456",
	role: "admin",
	username: "jonhdoe",
};
